<template>
    <div>
        <app-header :title="title" :isShowBack="true"></app-header>
        <div class="baseInfo">
            <div class="baseInfo-title">
                入库信息
            </div>
            <div class="form-box">
                <el-form ref="myForm" :model="myForm" :rules="rules">
                    <el-form-item label-width="120px" style="display: inline-block;" label="入库人" prop="name">
                        <el-input style="width: 320px;display: inline-block;" placeholder="请输入入库人"
                            v-model.trim="myForm.name" clearable>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>

        </div>
        <div class="goods">
            <div class="goods-title">入库商品</div>

            <div class="goods-search">
                <div>商品查询<span style="color:red;">*</span></div>
                <el-autocomplete v-model.trim="itemName" :fetch-suggestions="searchItem" placeholder="请输入商品名称/商品编号"
                    @select="handleSelect" clearable></el-autocomplete>
            </div>
            <div class="table-box">
                <div style="padding-bottom:8px;width:100px;">商品明细<span style="color:red;">*</span></div>
                <div class="goods-table">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
                        :header-cell-class-name="must" style="width: 100%;height:380px;overflow:auto;"
                        @selection-change="handSelectionChange">
                        <el-table-column type="selection" label="全选" width="55"></el-table-column>
                        <el-table-column label="商品名称" prop="goodsName"></el-table-column>
                        <el-table-column label="商品编号" prop="goodsCode"></el-table-column>
                        <el-table-column :label="isSellOnBusinessFalg ? '经销价（元/件）' : '成本价（元/件）'" :prop="isSellOnBusinessFalg ? 'distributorPrice' : 'goodsCost' "></el-table-column>
                        <el-table-column label="销售价（元/件）" prop="goodsPrice"></el-table-column>
                        <el-table-column prop="count" label="入库数量（件）">
                            <template slot-scope="scope">
                                <div class='tempList'>
                                    <el-input v-model.trim="scope.row.count" @blur="changeTable(scope.$index,scope.row)"
                                        placeholder="请输入">
                                    </el-input>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="isSellOnBusinessFalg ? '经销商名称' : '供应商名称'" :prop=" isSellOnBusinessFalg ? 'distributorName' : 'supplierName'"></el-table-column>

                    </el-table>
                    <div class="edit-tableBut-box">
                        <el-button class="edit-tableBut" @click="deleteRow()">删除行</el-button>
                        <!-- <el-button class="edit-tableBut" @click="addRow()">添加行</el-button> -->
                    </div>
                    <div class="goodsSubmit">
                        <el-button type="primary" @click="submit()">提交</el-button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    let reg = /(^-?[1-9]{1}[0-9]*$)/;
    export default {
        data() {
            return {
                title: '新增入库单',
                itemName: '',
                tableData: [],
                multipleSection: [],
                myForm: {
                    name: '',
                },
                rules: {
                    name: [{
                        required: true,
                        message: "请输入入库人",
                        trigger: ['change', 'blur'],
                        // validator: tradeNameVli
                    }],
                },
                restaurants: [],
                codeList: [],
                isSellOnBusinessFalg: false,// true--经销类的商家 false-- 普通商家
            }
        },
        activated() {
            this.tableData = [];
            this.myForm.name = '';
            this.itemName = '';
            this.codeList = [];
            this.$nextTick(() => {
                if (this.$refs["myForm"]) {
                    this.$refs["myForm"].resetFields(); //重置表单
                }
            })
            
            this.isSellOnBusinessFalg = this.isSellOnBusiness();
        },
        deactivated() {
            let pathList = this.$route.path.split('/')
            if (pathList[2] != 'storehouse') {
                this.cache.del('stockType');
            }
        },
        methods: {
            onSearch() {
                console.log('查询')
            },
            handSelectionChange(val) {
                // 选择的行
                this.multipleSection = val;
                console.log('multipleSection:', this.multipleSection)
            },
            changeTable(val, row) {
                this.tableData.forEach(item => {
                    if (item.index == row.index) {
                        item.count = row.count ? Number(row.count) : '';
                    }
                })
            },
            checkFun(row) {
                if (!row.count) {
                    this.$message({
                        message: '商品编号为' + row.goodsCode + '的入库数量不能为空或为0！',
                        type: 'warning',
                    })
                    return
                } else if (row.count && !reg.test(row.count)) {
                    this.$message({
                        message: '商品编号为' + row.goodsCode + '的入库数量不为整数！',
                        type: 'warning',
                    })
                    return
                }else {
                }
            },
            deleteRow() {
                if (this.multipleSection < 1) {
                    this.$message({
                        message: '至少选择一项！',
                        type: 'warning',
                    })
                    return
                }
                let selectIdList = [];
                this.multipleSection.forEach(item => {
                    selectIdList.push(item.index);
                })
                let saveList = [];
                this.codeList = [];
                this.tableData.forEach((item, index) => {
                    if (selectIdList.indexOf(item.index) == -1) {
                        saveList.push(item);
                        this.codeList.push(item.goodsCode);
                    }
                })
                this.tableData = saveList;
            },
            addRow() {
                console.log('添加行')
                this.tableData.splice(this.tableData.length, 0, {
                    goodsName: '',
                    goodsCode: '',
                    goodsCost: '',
                    distributorPrice: '',
                    goodsPrice: '',
                    count: '',
                    index: this.tableData.length,
                    type: 1,
                    supplierName:'',
                    supplierId: '',
                })
            },
            submit() {
                if (this.tableData.length < 1) {
                    this.$message({
                        message: '至少要录入一条商品数据',
                        type: 'warning'
                    })
                    return
                }
                let wrongfulFlag = false;
                this.tableData.find((item, index) => {
                    if(!item.count || !reg.test(item.count)) {
                       this.checkFun(item);
                       return wrongfulFlag = true;
                    }
                })
                if (wrongfulFlag) {
                    return
                }
                this.$refs['myForm'].validate((valid) => {
                    if (valid) {
                        let dto = {
                            goodsList: this.tableData,
                            name: this.myForm.name,
                        }
                        this.post('mall-service/v1/warehouse/in/save', dto, {
                            isUseResponse: true
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '新增入库单成功！',
                                    type: 'success',
                                })
                                this.$back();
                            }
                        })
                        console.log('提交tableData： ', this.tableData)
                    }
                })

            },
            searchItem(queryString, cb) {
                var restaurants = [];
                if(!this.itemName) {
                    cb(restaurants);
                    return
                }
                restaurants = this.restaurants;
                this.get('mall-service/items/queryInItemsByNameOrCode?data=' + this.itemName).then(res => {
                    restaurants = res;
                    if (restaurants.length > 0) {
                        restaurants.forEach(item => {
                            if(item.supplierName) {
                                item.value = item.name + "/" + item.supplierName;
                            }else {
                               item.value = item.name;
                            }
                            
                        })
                    }
                    cb(restaurants);
                })

            },
            createStateFilter(queryString) {
                return (state) => {
                    return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleSelect(item) {
                if (this.codeList.indexOf(item.code) != -1) {
                    this.$message({
                        message: '商品编号重复！',
                        type: 'warning'
                    })
                    this.$refs.multipleTable.setCurrentRow(item);
                    return

                }
                console.log(item);
                if(this.isSellOnBusinessFalg) {
                    this.tableData.unshift({
                        goodsName: item.name,
                        goodsCode: item.code,
                        goodsCost: item.costPrice ? Number(item.costPrice).toFixed(2) : 0.00,
                        goodsPrice: item.sellPrice ? Number(item.sellPrice).toFixed(2) : 0.00,
                        distributorPrice: item.supplyPrice ? Number(item.supplyPrice).toFixed(2) : 0.00,
                        goodsImages: item.pic,
                        goodsCategory: item.category,
                        count: '',
                        type: 1, //1-入库 2-出库
                        index: this.tableData.length,
                        // 经销类的商家一下几个数据需要这么传
                        supplierName: item.costName ? item.costName : '--',
                        supplierId: item.costId ? item.costId : null,
                        distributorName: item.supplierName ? item.supplierName : '--',
                        distributorId: item.supplierId ? item.supplierId : null,
                    })
                }else {
                    this.tableData.unshift({
                        goodsName: item.name,
                        goodsCode: item.code,
                        goodsCost: item.costPrice ? Number(item.costPrice).toFixed(2) : 0.00,
                        goodsPrice: item.sellPrice ? Number(item.sellPrice).toFixed(2) : 0.00,
                        goodsImages: item.pic,
                        goodsCategory: item.category,
                        count: '',
                        type: 1, //1-入库 2-出库
                        index: this.tableData.length,
                        supplierName: item.supplierName ? item.supplierName : '--',
                        supplierId: item.supplierId ? item.supplierId : null,
                    })
                }
                
                this.tableData.forEach(it => {
                    if (this.codeList.indexOf(it.goodsCode) == -1) {
                        this.codeList.push(it.goodsCode);
                    }
                })
                this.itemName = '';
            },
            must(obj) {
                // 库存数量加上 *号
                if (obj.columnIndex == 5) {
                    return 'must'
                }
            },
        },
    }
</script>
<style lang="scss" scoped>
    /deep/.el-table th.must>.cell:before {
        content: '*';
        color: red;
    }

    /deep/.el-table::before {
        z-index: 0;
    }

    .form-box {
        margin-left: 46px;
    }

    .baseInfo-title {
        margin-left: 46px;
        padding-bottom: 30px;
    }

    .goods-title {
        margin-left: 46px;
    }

    .goods-search {
        margin: 20px 92px;
        display: flex;

        div {
            line-height: 35px;
            padding-right: 5px;
        }
    }

    .table-box {
        margin-left: 92px;
        // display: flex;
    }

    .goods-table {
        height: 500px;
        // background: #eeeeee;
        margin-left: 68px;
    }

    .edit-tableBut-box {
        text-align: center
    }

    .edit-tableBut {
        margin: 20px 10px;
    }

    .goodsSubmit {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
</style>